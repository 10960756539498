import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Product, Category } from 'src/app/core/models';
import { ApiService } from 'src/app/core/services';
import { NotifyService } from 'src/app/core/services';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products: Product[] = [];
  categories: Category[] = [];
  display: boolean = false;
  product: any;

  constructor(
    private apiService: ApiService,
    public notify: NotifyService
  ) { }

  ngOnInit() {
    this.fetch();
  }

  add() {
    this.product = {};
    this.display = true;
  }

  refresh() {
    this.fetch();
  }

  fetch() {
    this.apiService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
        console.log(this.products);
      },
      (error) => {
        console.error('Error fetching products:', error);
      }
    );

    this.apiService.getCategories().subscribe(
      (categories: Category[]) => {
        this.categories = categories;
      },
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  delete(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteProduct(id).subscribe(
          () => {
            Swal.fire('Deleted!', 'This product has been deleted.', 'success');
            this.fetch();
          },
          (error) => {
            this.notify.error2("Oops, an error occurred :(");
            console.error('Error deleting product:', error);
          }
        );
      }
    });
  }

  edit(product: Product) {
    this.product = product;
    this.display = true;
  }

  saver(formData: any) {
    if (!formData.isAddForm) {
      this.updateProduct(formData);
    } else {
      this.addProduct(formData);
    }
    this.display = false;
    this.product = {};
  }

  addProduct(formData: any) {
    const data = {
      name: formData.name,
      price: formData.price,
      sku: formData.sku,
      stock: formData.stock,
      category_id: formData.category,
      status: formData.status,
      type: formData.type,
      components: formData.components || [],
      created_at: moment().format(),
      updated_at: moment().format()
    };
    console.log(data);
    this.apiService.addProduct(data).subscribe(
      () => {
        this.fetch();
      },
      (error) => {
        this.notify.error2("Oops, an error occurred :(");
        console.error('Error adding product:', error);
      }
    );
  }

  updateProduct(formData: any) {
    const data = {
      name: formData.name,
      price: Number(formData.price),  // Conversión a número
      sku: formData.sku,
      stock: Number(formData.stock),  // Conversión a número
      category_id: Number(formData.category),  // Asegúrate de que sea numérico
      status: formData.status,
      type: formData.type,
      components: formData.components || [],
      updated_at: moment().format()  // Formato de fecha en ISO
    };   

    this.apiService.updateProduct(formData.id, data).subscribe(
      () => {
        this.fetch();
      },
      (error) => {
        this.notify.error2("Oops, an error occurred :(");
        console.error('Error updating product:', error);
      }
    );
  }


}
