<div class="col-md-12">
  <p-table dataKey="id" stateKey="clients" [value]="clients" styleClass="p-datatable-striped" [rows]="10"
    [paginator]="true">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-md-6 text-start">
          <h5 class="mt-2">Clientes</h5>
        </div>
        <div class="col-md-6 text-right">
          <button (click)="refresh()" pButton type="button" icon="pi pi-refresh"
            class="p-button-sm p-button-link"></button>
            <button (click)="add()" label="Nuevo cliente" pButton type="button" icon="pi pi-plus"
            class="p-button-sm"></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
        <th pSortableColumn="email">Correo <p-sortIcon field="email"></p-sortIcon></th>
        <th pSortableColumn="phone">Teléfono <p-sortIcon field="phone"></p-sortIcon></th>
        <th pSortableColumn="address">Dirección <p-sortIcon field="address"></p-sortIcon></th>
        <th>Acciones</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-client>
      <tr>
        <td>{{ client.name }}</td>
        <td>{{ client.email }}</td>
        <td>{{ client.phone }}</td>
        <td>{{ client.address }}</td>
        <td>
          <button (click)="edit(client)" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
          <button (click)="viewDetails(client)" pButton icon="pi pi-eye" class="p-button-sm p-button-info mr-2"></button>
          <button (click)="delete(client.id)" pButton icon="pi pi-trash" class="p-button-sm p-button-danger"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">No se encontraron clientes.</td>
      </tr>
    </ng-template>
  </p-table>

  <!-- Dialogo de detalles del cliente -->
  <p-dialog [(visible)]="displayDetails" [modal]="true" [style]="{width: '60vw'}" header="Detalles del Cliente"
    (onHide)="closeDialog()">
    <div *ngIf="selectedClient">
      <h3>{{ selectedClient.name }}</h3>
      <p><strong>Correo:</strong> {{ selectedClient.email }}</p>
      <p><strong>Teléfono:</strong> {{ selectedClient.phone }}</p>
      <p><strong>Dirección:</strong> {{ selectedClient.address }}</p>

      <h4>Arriendos</h4>
      <ul>
        <li *ngFor="let rental of selectedClient.rentals">
          {{ rental.start_date | date }} - {{ rental.status }} - {{ rental.items.length }} productos
        </li>
      </ul>

      <h4>Ventas</h4>
      <ul>
        <li *ngFor="let sale of selectedClient.sales">
          {{ sale.sale_date | date }} - {{ sale.total_price | currency }}
        </li>
      </ul>
    </div>
  </p-dialog>
</div>

<p-dialog *ngIf="display" [header]="client.name ? 'Cliente: ' + client.name : 'Add new'" [resizable]="true"
  [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" [style]="{width: '50vw'}">
  <app-client [display]="display" [client]="client" (call)="saver($event)"></app-client>
</p-dialog>