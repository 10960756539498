import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';
import { ApiService, NotifyService } from 'src/app/core/services';
import { Product, Category } from 'src/app/core/models';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() product: any = {};
  @Input() display: boolean = false;
  @Output() call = new EventEmitter<any>();

  categories: any[] = [];
  products: any[] = [];
  isAddForm: boolean = false;
  showComponentsField: boolean = false;
  selectedType: string;  // Variable para el tipo seleccionado

  fields: FieldConfig[] = [];
  formConfig: FormConfig = {
    name: 'productForm',
    enctype: 'text/plain',
  };

  constructor(private apiService: ApiService, private notify: NotifyService) {}

  ngOnInit() {
    this.loadCategories();
    this.loadProducts();
    this.isAddForm = !this.product?.name;
    this.selectedType = this.product?.type || 'unique';
    this.showComponentsField = this.selectedType === 'combined';

    // Inicializar components si no existe para evitar errores
    this.product.components = this.product.components || [];

    // Configuración inicial de los campos del formulario
    this.fields = [
      { type: 'input', label: 'Name', inputType: 'text', name: 'name', col: 12, value: this.product?.name, validations: [{ name: 'required', validator: Validators.required, message: 'Name is required' }] },
      { type: 'input', label: 'Price', inputType: 'number', name: 'price', col: 12, value: this.product?.price, validations: [{ name: 'required', validator: Validators.required, message: 'Price is required' }] },
      { type: 'input', label: 'SKU', inputType: 'text', name: 'sku', col: 12, value: this.product?.sku, validations: [{ name: 'required', validator: Validators.required, message: 'SKU is required' }] },
      { type: 'input', label: 'Stock', inputType: 'number', name: 'stock', col: 12, value: this.product?.stock, validations: [{ name: 'required', validator: Validators.required, message: 'Stock is required' }] },
      { type: 'select', label: 'Category', name: 'category', col: 12, value: this.product?.category, options: this.categories },
      { type: 'select', label: 'Status', name: 'status', col: 12, value: this.product?.status || 'available', options: [{ label: 'Available', value: 'available' }, { label: 'Maintenance', value: 'maintenance' }] },
      {
        type: 'select',
        label: 'Type',
        name: 'type',
        col: 12,
        value: this.selectedType,
        options: [
          { label: 'Unique', value: 'unique' },
          { label: 'Combined', value: 'combined' }
        ]
      },
      { type: 'button', color: 'primary', label: this.isAddForm ? 'Save' : 'Update', col: 12 }
    ];
  }

  loadCategories() {
    this.apiService.getCategories().subscribe(
      (res: Category[]) => {
        this.categories = res.map(cat => ({ label: cat.name, value: cat.id }));
        this.fields.find(field => field.name === 'category').options = this.categories;
      },
      (error) => {
        this.notify.error2("Failed to load categories.");
        console.error(error);
      }
    );
  }

  loadProducts() {
    this.apiService.getProducts().subscribe(
      (res: Product[]) => {
        this.products = res
          .filter(product => product.type === 'unique')  // Solo permitir productos 'unique' como componentes
          .map(product => ({ label: product.name, value: product.id }));
      },
      (error) => {
        this.notify.error2("Failed to load products.");
        console.error(error);
      }
    );
  }

  toggleComponentsField(type: string) {
    this.showComponentsField = type === 'combined';
  }

  callBack(formData: any) {
    this.call.emit({
      id: this.product?.id,
      isAddForm: this.isAddForm,
      ...formData
    });
  }
}
