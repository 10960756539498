import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/core/models';
import { ApiService, NotifyService } from 'src/app/core/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  clients: Client[] = [];
  selectedClient: Client | null = null;
  displayDetails: boolean = false;
  display: boolean = false;
  client: any;

  constructor(private apiService: ApiService, private notify: NotifyService) {}

  ngOnInit(): void {
    this.fetchClients();
  }

  fetchClients(): void {
    this.apiService.getClients().subscribe(
      (res: Client[]) => {
        this.clients = res;
      },
      (error) => {
        console.error('Error fetching clients:', error);
        this.notify.error2('Error al cargar clientes.');
      }
    );
  }

  refresh(): void {
    this.fetchClients();
  }

  add(): void {
    this.client = {};
    this.display = true;
  }

  delete(id: string): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminarlo'
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService.deleteClient(id).subscribe(
          () => {
            Swal.fire('Deleted!', 'This product has been deleted.', 'success');
            this.fetchClients();
          },
          (error) => {
            console.error('Error deleting client:', error);
            this.notify.error2('Error al eliminar cliente.');
          }
        );
      }
    });
  }

  viewDetails(client: Client): void {
    this.selectedClient = client;
    this.displayDetails = true;
  }

  closeDialog(): void {
    this.displayDetails = false;
    this.selectedClient = null;
  }

  edit(client: Client) {
    this.client = client;
    this.display = true;
  }

  saver(formData: any) {
    if (!formData.isAddForm) {
      console.log("aaa");
      this.updateClients(formData);
    } else {
      this.addClient(formData);
    }
    this.display = false;
    this.client = {};
  }

  updateClients(formData: any) {
    const data = {
      name: formData.name,
      email: formData.email,      // Incluye el email
      phone: formData.phone,      // Incluye el teléfono
      address: formData.address   // Incluye la dirección
    };

    this.apiService.updateClient(formData.id, data).subscribe(
      () => {
        this.fetchClients();
      },
      (error) => {
        this.notify.error2("Oops, an error occurred :(");
        console.error('Error updating client:', error);
      }
    );
  }

  addClient(formData: any) {
    const data = {
      name: formData.name,
      email: formData.email,      // Incluye el email
      phone: formData.phone,      // Incluye el teléfono
      address: formData.address   // Incluye la dirección
    };
  
    this.apiService.addClient(data).subscribe(
      () => {
        this.fetchClients();
        this.notify.success('Cliente agregado exitosamente.');
      },
      (error) => {
        this.notify.error2("Oops, an error occurred :(");
        console.error('Error adding client:', error);
      }
    );
  }  
}
