import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  @Input() client: any = {};
  @Input() display: boolean = false;
  @Output() call = new EventEmitter<any>();

  isAddForm: boolean = false;

  fields: FieldConfig[] = [];
  formConfig: FormConfig = {
    name: 'clientForm',
    enctype: 'text/plain',
  };

  constructor() { }

  ngOnInit() {
    this.isAddForm = !this.client?.name;

    this.fields = [
      {
        type: 'input',
        label: 'Name',
        inputType: 'text',
        name: 'name',
        value: this.client?.name,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Name is required'
        }]
      },
      {
        type: 'input',
        label: 'Email',
        inputType: 'email',
        name: 'email',
        value: this.client?.email,
        col: 12,
        validations: [{
          name: 'pattern',
          validator: Validators.email,
          message: 'Enter a valid email'
        }]
      },
      {
        type: 'input',
        label: 'Phone',
        inputType: 'text',
        name: 'phone',
        value: this.client?.phone,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.pattern("^[0-9]*$"),
          message: 'Enter a valid phone number'
        }]
      },
      {
        type: 'input',
        label: 'Address',
        inputType: 'text',
        name: 'address',
        value: this.client?.address,
        col: 12
      },
      { type: 'button', color: 'primary', label: this.isAddForm ? 'Save' : 'Update', col: 12 }
    ];
  }

  callBack(formData: any) {
    this.call.emit({
      id: this.client?.id,
      isAddForm: this.isAddForm,
      ...formData
    });
  }
}
