import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';  // Importa Validators
import { FieldConfig, FormConfig } from 'ngx-nomad-form';
import { AuthenticationService, NotifyService } from 'src/app/core/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formConfig: FormConfig = {
    name: 'loginForm',
    enctype: 'text/plain',
  };

  fields: FieldConfig[] = [
    {
      type: 'input',
      label: 'Email',
      inputType: 'email',
      name: 'email',
      value: '',
      col: 12,
      validations: [
        { name: 'required', validator: Validators.required, message: 'Email is required' }
      ]
    },
    {
      type: 'input',
      label: 'Clave',
      inputType: 'password',
      name: 'password',
      value: '',
      col: 12,
      validations: [
        { name: 'required', validator: Validators.required, message: 'Password is required' }
      ]
    },
    {
      type: 'button',
      color: 'primary',
      label: 'Login',
      col: 12
    }
  ];

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notify: NotifyService
  ) {}

  ngOnInit() {
    if (this.authService.checkIfUserIsConnected()) {
      this.redirect();
    }
  }

  callBack(formData: any) {
    this.auth(formData);
  }

  private auth(formData: any) {
    this.authService.loginUser(formData.email, formData.password).subscribe(
      (res: any) => {
        this.notify.success('Inicio de sesión exitoso');
        this.redirect();
      },
      (error) => {
        this.notify.error2('Error en la autenticación');
        console.error('Error en la autenticación:', error);
      }
    );
  }

  private redirect() {
    this.router.navigateByUrl('admin/dashboard', { state: { reload: true } });
  }
}
