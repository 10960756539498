// src/app/core/models/product.model.ts
export class Product {
  id: string;
  name: string;
  price: number;
  sku: string;
  stock: number;
  category: string; 
  status: string;
  type: string;
  components: number[];
  updated_at: Date;
  created_at: Date;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.name = obj.name;
    this.price = obj.price;
    this.sku = obj.sku;
    this.stock = obj.stock;
    this.category = obj.category;
    this.status = obj.status;
    this.type = obj.type;
    this.components = obj.components || [];
    this.updated_at = obj.updated_at;
    this.created_at = obj.created_at;
  }
}
