import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor() { }

  error(x) {
    Swal.fire({
      title: x.code,
      text: x.message,
      icon: "error",
      customClass: {
        popup: 'swal-overlay' // Clase personalizada
      }
    });
  }

  error2(a: string) {
    Swal.fire({
      title: "Oup's",
      text: a,
      icon: "error",
      customClass: {
        popup: 'swal-overlay' // Clase personalizada
      }
    });
  }

  success(a: string) {
    Swal.fire({
      title: "Super",
      text: a,
      icon: "success",
      customClass: {
        popup: 'swal-overlay' // Clase personalizada
      }
    });
  }

  info(message: string) {
    Swal.fire({
      title: "Información",
      text: message,
      icon: "info",
      customClass: {
        popup: 'swal-overlay' // Clase personalizada si tienes el estilo swal-overlay
      }
    });
  }
  
}
