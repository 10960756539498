import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotifyService } from '../notify/notify.service';
import { User } from '../../models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private baseUrl =  environment.api_url;

  constructor(private http: HttpClient, private router: Router, private notify: NotifyService) { }

  loginUser(email: string, password: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/auth/login`, { username: email, password: password }).pipe(
      map((response: any) => {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('user', JSON.stringify(response));
        return response;
      }),
      catchError((error) => {
        // Manejando el error
        if (error.status === 401) {
          this.notify.error2('Credenciales incorrectas. Verifica tu email y contraseña.');
        } else {
          this.notify.error2('Error al intentar iniciar sesión.');
        }
        return throwError(error);
      })
    );
  }

  // Verificar si el usuario está conectado
  checkIfUserIsConnected(): boolean {
    return !!localStorage.getItem('access_token');
  }

  signOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    this.router.navigate(['/home']);
  }

  getUserData(): { role: string, user_id: number } | null {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }

}
