<div class="container-fluid padding">
  <div class="row mb-3">
    <div class="col-md-8">
      <input type="text" placeholder="Buscar producto..." [(ngModel)]="searchTerm" (input)="filterProducts()"
        class="form-control mb-3" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <p-tabView>
          <p-tabPanel [header]="category.name" *ngFor="let category of categories">
            <p-dataView [value]="isGlobalFilterActive ? filteredGlobalProducts : category.filteredProducts"
              [paginator]="isGlobalFilterActive ? filteredGlobalProducts.length > 10 : category?.filteredProducts?.length > 10"
              [rows]="10" layout="grid">
              <ng-template let-product pTemplate="gridItem" class="bg-transparent row">
                <div class="p-col-12 pl-0 cp" (click)="addToBasket(product)" [class.disabled]="product.stock === 0">
                  <div class="media bg-blur rounded m-1">
                    <img class="mr-3"
                      [src]="product.image || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaCYt_Skg_DdS56k7TJ6K6bjyh2l-8W_3_WA&s'"
                      width="50" alt="Imagen del producto">
                    <div class="media-body">
                      <h5 class="mt-0">{{ product.name }} (Stock: {{ product.stock }}) | {{product.sku}}</h5>
                      <b>{{ product.price | currency }}</b>
                      <br>
                      <span *ngIf="product.stock === 0" class="text-danger">No disponible</span>
                      <span *ngIf="product.status === 'rented'" class="text-danger">Arrendado</span>
                      <span *ngIf="product.status === 'maintenance'" class="text-danger">En Matención</span>

                    </div>
                  </div>
                </div>
              </ng-template>
            </p-dataView>
          </p-tabPanel>

        </p-tabView>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div>
          <div style="min-height: 520px !important;">
            <table class="table text-dark">
              <thead>
                <tr>
                  <th class="text-capitalize">$</th>
                  <th class="text-capitalize">Producto</th>
                  <th class="text-capitalize">Precio</th>
                  <th class="text-capitalize text-center">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of basket">
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.price | currency:'USD':'symbol':'1.0-0' }}</td>
                  <td class="text-right">
                    <button class="btn btn-primary btn-sm px-2 py-1 mr-1" (click)="increaseQuantity(item)">+</button>
                    <button class="btn btn-warning btn-sm px-2 py-1 mr-1" (click)="decreaseQuantity(item)">-</button>
                    <button class="btn btn-danger btn-sm px-2 py-1" (click)="removeFromBasket(item)">x</button>
                  </td>
                </tr>
                <tr *ngIf="basket.length === 0">
                  <td colspan="4">Sin productos.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="container m-2">
            <div class="row">
              <div class="col-md-6">
                <p class="mt-2 text-dark"><span class="mr-3">Subtotal:</span> <strong>{{ cartTotal |
                    currency:'USD':'symbol':'1.0-0' }}</strong></p>
              </div>
              <div class="col-md-6">
                <label for="discount">Descuento (%):</label>
                <input id="discount" type="number" [(ngModel)]="discountPercentage" (input)="applyDiscount()" min="0"
                  max="100" class="form-control" />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-6">
                <p class="text-dark"><span class="mr-3">IVA de la compra es:</span> <strong>{{ taxAmount |
                    currency:'USD':'symbol':'1.0-0' }}</strong></p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-6">
                <p class="text-dark"><span class="mr-3">Total con descuento:</span> <strong>{{ discountedTotal |
                    currency:'USD':'symbol':'1.0-0' }}</strong></p>
              </div>
              <div class="col-md-3 text-right">
                <button class="btn btn-primary mr-2" (click)="openCheckoutModal('sale')">Generar Venta</button>
              </div>
              <div class="col-md-3 text-right">
                <button class="btn btn-success" (click)="openCheckoutModal('rental')">Generar Arriendo</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal para seleccionar cliente e información adicional de arriendo o venta -->
<p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" header="Confirmar Operación">
  <div *ngIf="selectedType === 'sale'">
    <h5>Confirmar Venta</h5>
  </div>
  <div *ngIf="selectedType === 'rental'">
    <h5>Confirmar Arriendo</h5>
  </div>
  <div class="form-group">
    <label for="client">Cliente:</label>
    <select id="client" [(ngModel)]="selectedClient" class="form-control" required>
      <option *ngFor="let client of clients" [ngValue]="client">{{ client.name }}</option>
    </select>
  </div>
  <div class="form-group" *ngIf="selectedType === 'rental'">
    <label for="startDate">Fecha de Inicio:</label>
    <input id="startDate" type="date" [(ngModel)]="rentalStartDate" class="form-control" required />
    <label for="endDate">Fecha de Fin:</label>
    <input id="endDate" type="date" [(ngModel)]="rentalEndDate" class="form-control" required />
  </div>
  <div class="form-group">
    <label for="installments">Cantidad de Cuotas:</label>
    <input id="installments" type="number" [(ngModel)]="installmentCount" (input)="calculateInstallments(selectedType)"
      class="form-control" min="1" />
  </div>
  <div class="form-group">
    <p><strong>Total con descuento: </strong>{{ discountedTotal | currency:'USD':'symbol':'1.0-0' }}</p>
  </div>
  <div class="form-group" *ngIf="installments.length > 0">
    <h5>Desglose de Cuotas:</h5>
    <ul>
      <li *ngFor="let installment of installments">
        Pago {{ installment.number }} - Fecha: {{ installment.date }} - Monto: {{ installment.amount |
        currency:'USD':'symbol':'1.0-0' }}
      </li>
    </ul>
  </div>
  <p-footer>
    <button class="btn btn-primary" (click)="confirmCheckout()">Confirmar</button>
    <button class="btn btn-secondary" (click)="displayModal = false">Cancelar</button>
  </p-footer>
</p-dialog>