import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { NotifyService } from '../notify/notify.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private notify: NotifyService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const user = this.authService.getUserData();
    const expectedRoles = route.data['expectedRole'];

    console.log(user);
    console.log(expectedRoles);

    // Si `expectedRoles` no está definido, permite el acceso automáticamente
    if (!expectedRoles) {
      return true;
    }

    // Verifica si el usuario tiene un rol permitido
    if (!user || !expectedRoles.includes(user.role)) {
      this.notify.error2('No tienes acceso para esta sección.');
      return false; // Bloquea el acceso
    }

    return true; // Permite el acceso si el rol es válido
  }

}