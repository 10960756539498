<div class="col-md-12">
  <p-table dataKey="id" stateKey="products" [value]="products" styleClass="p-datatable-striped" [rows]="10"
    [paginator]="true" #yp1 lang="fr">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-md-6 text-start">
          <h5 class="mt-2">Products</h5>
        </div>
        <div class="col-md-6 text-right">
          <button (click)="refresh()" pButton type="button" icon="pi pi-refresh"
            class="p-button-sm p-button-link mr-2"></button>
          <button (click)="add()" label="Nuevo producto" pButton type="button" icon="pi pi-plus"
            class="p-button-sm"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Nombre
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="price">Precio <p-sortIcon field="price"></p-sortIcon></th>
        <th pSortableColumn="sku">SKU <p-sortIcon field="sku"></p-sortIcon></th>
        <th pSortableColumn="stock">Stock <p-sortIcon field="stock"></p-sortIcon></th>
        <th pSortableColumn="category">Categoria <p-sortIcon field="category"></p-sortIcon></th>
        <th pSortableColumn="status">Estatus <p-sortIcon field="status"></p-sortIcon></th>
        <th pSortableColumn="type">Type<p-sortIcon field="type"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>{{ product.name }}</td>
        <td>{{ product.price | currency:'USD':'symbol':'1.0-0' }}</td>
        <td>{{ product.sku}}</td>
        <td>{{ product.stock }}</td>
        <td>{{ product.category }}</td>
        <td><span class="badge bg-primary text-white" *ngIf="product.status">{{product.status}}</span></td>
        <td>{{ product.type }}</td>
        <td class="text-right">
          <button (click)="edit(product)" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
          <button (click)="delete(product.id)" pButton type="button" icon="pi pi-trash"
            class="p-button-sm p-button-danger"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="d-none">
      <tr>
        <td colspan="7">No products found.</td>
      </tr>
    </ng-template>
  </p-table>

</div>

<p-dialog *ngIf="display" [header]="product.name ? 'Category: ' + product.name : 'Agregar nuevo producto'" [resizable]="true"
  [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" [style]="{width: '50vw'}">
  <app-product [display]="display" [product]="product" (call)="saver($event)"></app-product>
</p-dialog>