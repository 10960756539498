<div class="col-md-12">
  <p-table dataKey="id" [value]="rentals" styleClass="p-datatable-striped" [rows]="10" [paginator]="true">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-md-6 text-start">
          <h5 class="mt-2">Rentals</h5>
        </div>
        <div class="col-md-6 text-right">
          <button (click)="refresh()" pButton type="button" icon="pi pi-refresh"
            class="p-button-sm p-button-link"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id"># <p-sortIcon field="id"></p-sortIcon></th>
        <th pSortableColumn="client.name" class="text-right">Cliente <p-sortIcon field="client.name"></p-sortIcon></th>
        <th pSortableColumn="items">Items <p-columnFilter field="items" matchMode="contains"
            display="menu"></p-columnFilter></th>
        <th pSortableColumn="payment_status">Estado <p-sortIcon field="payment_status"></p-sortIcon></th>
        <th>Cuotas</th>
        <th>$ Cuota</th>
        <th pSortableColumn="start_date" class="text-right">Date Rental <p-sortIcon field="start_date"></p-sortIcon>
        </th>
        <th pSortableColumn="end_date" class="text-right">Date end Rental <p-sortIcon field="end_date"></p-sortIcon>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rental>
      <tr>
        <td>{{ rental.id }}</td>
        <td>{{ rental.client.name }}</td>
        <td>
          <span *ngFor="let item of rental.items" class="badge badge-light mr-1 font-weight-normal">
            {{ item.quantity }} x {{ item.product_name }}
          </span>
        </td>
        <td>{{ rental.payment_status }}</td>
        <td>{{ rental.installment_count }}</td>
        <td>{{ rental.installment_amount | currency: 'USD':'symbol':'1.0-0' }}</td>
        <td>{{ rental.start_date | date: 'dd/MM/yyyy' }}</td>
        <td>{{ rental.end_date | date: 'dd/MM/yyyy' }}</td>
        <td>
          <button (click)="viewInstallments(rental.id)" pButton type="button" icon="pi pi-eye"
            class="p-button-sm p-button-info"></button>
          <button (click)="openReturnModal(rental)" pButton type="button" icon="pi pi-undo"
            class="p-button-sm p-button-warning"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="9">No rentals found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="Cuotas" [(visible)]="showInstallmentsModal" [modal]="true" [style]="{width: '50vw'}">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th># Cuota</th>
        <th>Fecha</th>
        <th>Monto</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let installment of installments">
        <td>{{ installment.installment_number }}</td>
        <td>{{ installment.date | date: 'dd/MM/yyyy' }}</td>
        <td>{{ installment.amount | currency: 'USD':'symbol':'1.0-0' }}</td>
        <td>
          <button (click)="sendNotificationEmail(installment)" pButton label="Enviar Correo" icon="pi pi-envelope"
            class="p-button-info p-button-sm"></button>

        </td>
      </tr>
    </tbody>
  </table>
  <p-footer>
    <button (click)="closeInstallmentsModal()" pButton label="Cerrar" icon="pi pi-times"
      class="p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Editar Arriendo" [(visible)]="showReturnModal" [modal]="true" [style]="{width: '50vw'}">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Producto</th>
        <th>Cantidad</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of selectedRentalItems">
        <td>{{ item.product_name }}</td>
        <td>
          <input type="number" [(ngModel)]="item.returnQuantity" [max]="item.quantity" class="form-control" />
        </td>
        <td>
          <button (click)="returnProduct(item)" pButton label="Devolver" icon="pi pi-undo"
            class="p-button-danger p-button-sm"></button>
        </td>
      </tr>
    </tbody>
  </table>
  <p-footer>
    <button (click)="closeRental()" pButton label="Cerrar Arriendo" icon="pi pi-check" class="p-button-danger"></button>
    <button (click)="closeReturnModal()" pButton label="Cerrar" icon="pi pi-times" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>