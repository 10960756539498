<div class="header">
  <div class="header-content">
    <nav class="navbar navbar-expand">
      <div class="collapse navbar-collapse justify-content-between">
        <div class="header-left">
        </div>

        <ul class="navbar-nav header-right">
          <li class="nav-item header-profile">
            <a class="nav-link" role="button">
              <div class="header-info text-right mr-2">
                <span><strong>{{ user.name }}</strong></span>
                <small>Usuario</small>
              </div>
              <img src="https://ui-avatars.com/api/?name={{ user.email }}"  alt="" width="20">

            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
