<div class="nav-header">
  <a [routerLink]="['dashboard']" class="brand-logo">
      <img class="logo-abbr" src="assets/img/logo.png" alt="logo">
      <img class="logo-compact" src="assets/img/logo.png" alt="logo">
      <img class="brand-title" src="assets/img/logo.png" alt="logo">
  </a>

  <div class="nav-control">
      <div class="hamburger">
          <span class="line"></span><span class="line"></span><span class="line"></span>
      </div>
  </div>
</div>
