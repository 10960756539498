import { Client } from './client';
import { Installment } from './installment';

export class Rental {
  id: string;
  startDate: Date;
  endDate: Date | null;
  status: string;
  userId: number;
  client: Client;
  paymentStatus: string;
  installmentCount?: number;
  installmentAmount?: number;
  items: RentalItem[];
  installments: Installment[];  // Nueva propiedad para las cuotas

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.startDate = new Date(obj.startDate);
    this.endDate = obj.endDate ? new Date(obj.endDate) : null;
    this.status = obj.status;
    this.userId = obj.userId;
    this.client = new Client(obj.client);
    this.paymentStatus = obj.paymentStatus;
    this.installmentCount = obj.installmentCount;
    this.installmentAmount = obj.installmentAmount;
    this.items = obj.items ? obj.items.map((item: any) => new RentalItem(item)) : [];
    this.installments = obj.installments ? obj.installments.map((inst: any) => new Installment(inst)) : []; // Inicialización de cuotas
  }
}

export class RentalItem {
  product_id: number;
  productName: string;
  quantity: number;
  unitPrice: number;
  returnQuantity?: number;  // Propiedad opcional para la cantidad de devolución

  constructor(obj: any = {}) {
    this.product_id = obj.productId;
    this.productName = obj.productName;
    this.quantity = obj.quantity;
    this.unitPrice = obj.unitPrice;
    this.returnQuantity = obj.returnQuantity || 0;  // Inicialización opcional
  }
}
