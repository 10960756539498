// installment.model.ts
export class Installment {
    number: number;
    date: Date;
    amount: number;
  
    constructor(obj: any = {}) {
      this.number = obj.number;
      this.date = new Date(obj.date);
      this.amount = obj.amount;
    }
  }
  