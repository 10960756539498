<div id="main-wrapper">

  <!--**********************************
      Nav header start
  ***********************************-->
  <app-navbar></app-navbar>
  <!--**********************************
      Nav header end
  ***********************************-->


  <!--**********************************
      Sidebar Fixed
  ***********************************-->
  <app-sidebar></app-sidebar>
  <!--**********************************
      Sidebar End
  ***********************************-->


  <!--**********************************
      Header start
  ***********************************-->
  <app-header></app-header>
  <!--**********************************
      Header end ti-comment-alt
  ***********************************-->

  <!--**********************************
      Content body start
  ***********************************-->
  <div class="content-body" style="min-height: 850px;">
    <!-- row -->
    <div class="container">
        <router-outlet></router-outlet>
    </div>
  </div>

  <!--**********************************
      Content body end
  ***********************************-->

  <!--**********************************
      Footer start
  ***********************************-->
  <app-footer></app-footer>
  <!--**********************************
      Footer end
  ***********************************-->


</div>
