import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.api_url;

  constructor(private http: HttpClient) { }

  // Categorías
  getCategories(): Observable<any> {
    return this.http.get(`${this.baseUrl}/categories`);
  }

  addCategory(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/categories/`, data, { headers: { 'Content-Type': 'application/json' } });
  }

  updateCategory(id: string, data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/categories/${id}`, data);
  }

  deleteCategory(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/categories/${id}`);
  }

  // Productos
  getProducts(): Observable<any> {
    return this.http.get(`${this.baseUrl}/products`);
  }

  getProductsByCategory(categoryId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/categories/${categoryId}/products`);
  }

  addProduct(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/products/`, data, { headers: { 'Content-Type': 'application/json' } });
  }

  updateProduct(id: string, data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/products/${id}`, data);
  }

  deleteProduct(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/products/${id}`);
  }

  // Ventas
  getSales(): Observable<any> {
    return this.http.get(`${this.baseUrl}/sales`);
  }

  addSale(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/sales/`, data, { headers: { 'Content-Type': 'application/json' } });
  }

  updateSale(id: string, data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/sales/${id}`, data);
  }

  deleteSale(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/sales/${id}`);
  }

  // Clientes
  getClients(): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients`);
  }

  addClient(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/clients/`, data, { headers: { 'Content-Type': 'application/json' } });
  }

  updateClient(id: string, data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/clients/${id}`, data);
  }

  deleteClient(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/clients/${id}`);
  }

  // Usuarios
  getUsers(): Observable<any> {
    return this.http.get(`${this.baseUrl}/users`);
  }

  // Arriendos
  getRentals(): Observable<any> {
    return this.http.get(`${this.baseUrl}/rentals`);
  }

  addRental(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/rentals/`, data, { headers: { 'Content-Type': 'application/json' } });
  }

  updateRental(id: string, data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/rentals/${id}`, data);
  }

  deleteRental(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/rentals/${id}`);
  }

  returnRentalItem(productId: number, quantity: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/rentals/return_item`, { product_id: productId, quantity });
  }

  closeRental(rentalId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/rentals/close`, { rentalId });
  }

  // Guías de Despacho
  getDispatchGuides(): Observable<any> {
    return this.http.get(`${this.baseUrl}/dispatch_guides`);
  }

  generateSaleDispatchGuide(data: any): Observable<Blob> {
    return this.http.post(`${this.baseUrl}/sales/generate_dispatch_guide`, data, {
      responseType: 'blob',
    });
  }

  // Método para generar la guía de despacho de arriendo
  generateRentalDispatchGuide(data: any): Observable<Blob> {
    return this.http.post(`${this.baseUrl}/rentals/generate_dispatch_guide`, data, {
      responseType: 'blob',
    });
  }

  getRentalInstallments(rentalId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/installments/rental/${rentalId}`);
  }

  getSaleInstallments(saleId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/installments/sale/${saleId}`);
  }

  sendInstallmentEmailNotification(installmentId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/installments/${installmentId}/notify`, {});
  }

}
