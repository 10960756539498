import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { Rental, RentalItem, Client, Installment } from 'src/app/core/models';
import { NotifyService, ApiService } from 'src/app/core/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rentals',
  templateUrl: './rental.component.html',
  styleUrls: ['./rental.component.scss']
})
export class RentalComponent implements OnInit {
  rentals: Rental[] = [];
  display: boolean = false;
  rental: Rental | null = null;
  client: Client | null = null;
  installments: Installment[] = [];  // Propiedad para almacenar las cuotas
  showInstallmentsModal: boolean = false;  // Controla la visibilidad del modal de cuotas
  showReturnModal: boolean = false;
  selectedRentalItems: RentalItem[] = [];

  constructor(private apiService: ApiService, public notify: NotifyService, private afs: AngularFirestore) { }

  ngOnInit() {
    this.fetch();
  }

  add() {
    this.rental = new Rental(); // Inicializa un nuevo arriendo
    this.display = true;
  }

  refresh() {
    this.fetch();
  }

  fetch() {
    this.apiService.getRentals().subscribe(
      (res: Rental[]) => {
        this.rentals = res;
        console.log(this.rentals);
      },
      (error) => {
        console.error('Error fetching rentals:', error);
      }
    );
  }

  delete(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteRental(id).subscribe(
          () => {
            Swal.fire('Deleted!', 'This rental has been deleted.', 'success');
            this.fetch();
          },
          (error) => {
            this.notify.error2("Oops, an error occurred :(");
            console.error('Error deleting rental:', error);
          }
        );
      }
    });
  }

  edit(rental: Rental) {
    this.rental = rental;
    this.display = true;
  }

  saver(formData: any) {
    console.log(formData)
    if (formData.id) {
      this.updateRental(formData);
    } else {
      this.addRental(formData);
    }
    this.display = false;
    this.rental = null;
  }

  addRental(formData: any) {
    const data = {
      startDate: formData.startDate,
      endDate: formData.endDate || null,
      status: formData.status,
      userId: formData.userId,
      clientId: formData.clientId,
      paymentStatus: formData.paymentStatus,
      installmentCount: formData.installmentCount || null,
      installmentAmount: formData.installmentAmount || null,
      items: formData.items || [], // Lista de items (productos) en el arriendo
      createdAt: moment().format(),
      updatedAt: moment().format()
    };
    console.log(data);

    this.apiService.addRental(data).subscribe(
      () => {
        this.fetch();
      },
      (error) => {
        this.notify.error2("Oops, an error occurred :(");
        console.error('Error adding rental:', error);
      }
    );
  }

  updateRental(formData: any) {
    const data = {
      startDate: formData.startDate,
      endDate: formData.endDate || null,
      status: formData.status,
      userId: formData.userId,
      clientId: formData.clientId,
      paymentStatus: formData.paymentStatus,
      installmentCount: formData.installmentCount || null,
      installmentAmount: formData.installmentAmount || null,
      items: formData.items || [], // Lista de items (productos) en el arriendo
      updatedAt: moment().format()
    };

    this.apiService.updateRental(formData.id, data).subscribe(
      () => {
        this.fetch();
      },
      (error) => {
        this.notify.error2("Oops, an error occurred :(");
        console.error('Error updating rental:', error);
      }
    );
  }

  viewInstallments(rentalId: string) {
    this.apiService.getRentalInstallments(rentalId).subscribe(
      (installments: Installment[]) => {
        if (installments.length === 0) {
          // Mostrar mensaje de "No tiene cuotas"
          this.notify.info("No tiene cuotas");
        } else {
          // Si tiene cuotas, asignarlas y mostrar el modal
          this.installments = installments;
          this.showInstallmentsModal = true;
        }
      },
      (error) => {
        this.notify.error2("Oops, an error occurred fetching installments.");
        console.error('Error fetching installments:', error);
      }
    );
  }


  closeInstallmentsModal() {
    this.showInstallmentsModal = false;
    this.installments = [];
  }

  sendNotificationEmail(installment: any) {
    this.apiService.sendInstallmentEmailNotification(installment.id).subscribe(
      () => {
        this.closeInstallmentsModal();
        this.notify.success("Notificación de correo enviada correctamente.");
      },
      (error) => {
        this.notify.error2("Error al enviar el correo de notificación.");
        console.error("Error sending email notification:", error);
      }
    );
  }

  openReturnModal(rental: Rental) {
    this.rental = rental; // Asigna el arriendo actual a this.rental
    this.selectedRentalItems = rental.items.map(item => ({ ...item, returnQuantity: 0 }));
    this.showReturnModal = true;
  }


  // Función para cerrar el modal de devolución
  closeReturnModal() {
    this.showReturnModal = false;
    this.selectedRentalItems = [];
  }

  // Función para devolver un producto específico
  returnProduct(item: RentalItem) {
    if (item.returnQuantity > 0 && item.returnQuantity <= item.quantity) {
      this.apiService.returnRentalItem(item.product_id, item.returnQuantity).subscribe(
        () => {
          // Actualizar la cantidad del producto en el modal
          item.quantity -= item.returnQuantity;

          // Si la cantidad es 0, elimina el producto de la lista
          if (item.quantity === 0) {
            this.selectedRentalItems = this.selectedRentalItems.filter(i => i.product_id !== item.product_id);
          }

          // Recargar datos para actualizar stocks globalmente
          this.fetch();
        },
        (error) => {
          this.notify.error2("Error al devolver el producto.");
          console.error("Error returning product:", error);
        }
      );
    }
  }

  closeRental() {
    // Verificar si this.rental está definido
    if (!this.rental || !this.rental.id) {
      this.notify.error2("No se puede cerrar el arriendo porque no está seleccionado.");
      return;
    }

    Swal.fire({
      title: '¿Estás seguro de cerrar el arriendo?',
      text: "Esto marcará todas las cuotas como pagadas y devolverá todos los productos.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cerrar arriendo'
    }).then((result) => {
      if (result.isConfirmed) {
        // Llamar a la API para cerrar el arriendo
        this.apiService.closeRental(Number(this.rental.id)).subscribe(
          () => {
            this.notify.success("Arriendo cerrado correctamente.");
            this.fetch(); // Actualizar la lista de arriendos
            this.closeReturnModal(); // Cerrar el modal
          },
          (error) => {
            this.notify.error2("Error al cerrar el arriendo.");
            console.error("Error closing rental:", error);
          }
        );
      }
    });
  }

}
