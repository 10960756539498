import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Category, Product, Client } from 'src/app/core/models';
import { ApiService, NotifyService } from 'src/app/core/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {
  products: Product[] = [];
  basket: any[] = [];
  cartTotal: number = 0;
  discountedTotal: number = 0;
  discountPercentage: number = 0;
  categories: Category[] = [];
  clients: Client[] = [];
  selectedClient: Client | null = null;
  rentalStartDate: string;
  rentalEndDate: string;
  selectedType: 'sale' | 'rental' | null = null;
  displayModal: boolean = false;
  installmentCount: number = 1;
  installments: { number: number; date: string; amount: number }[] = [];
  taxRate: number = 0.19; // Ejemplo: 19% de IVA
  taxAmount: number = 0;  // Monto de IVA calculado
  searchTerm: string = '';

  allProducts: Product[] = []; // Almacenará todos los productos de todas las categorías
  isGlobalFilterActive: boolean = false;
  filteredGlobalProducts: Product[] = [];

  constructor(
    private apiService: ApiService,
    private notify: NotifyService,
  ) { }

  ngOnInit() {
    this.loadCategories();
    this.loadClients();
  }

  loadCategories() {
    this.apiService.getCategories().subscribe(
      (categories: Category[]) => {
        this.categories = categories;
        this.categories.forEach(category => {
          this.loadProducts(category);
        });
      },
      (error) => {
        this.notify.error2("Error al cargar categorías.");
      }
    );
  }

  loadClients() {
    this.apiService.getClients().subscribe(
      (clients: Client[]) => {
        this.clients = clients;
      },
      (error) => {
        this.notify.error2("Error al cargar clientes.");
      }
    );
  }

  loadProducts(category: Category) {
    this.apiService.getProductsByCategory(category.id).subscribe(
      (products: Product[]) => {
        category.products = products;
        category.filteredProducts = products; // Inicializamos filteredProducts con todos los productos
        this.allProducts = [...this.allProducts, ...products]; // Agregamos a la lista global
      },
      (error) => {
        this.notify.error2("Error al cargar productos.");
      }
    );
  }


  addToBasket(product: Product) {
    const existingItem = this.basket.find(item => item.id === product.id);
    if (existingItem) {
      if (existingItem.quantity < product.stock) { // Validación de stock
        existingItem.quantity += 1;
      } else {
        this.notify.error2("No puede agregar más de lo que hay en stock.");
      }
    } else {
      this.basket.push({ ...product, quantity: 1 });
    }
    this.calculateTotal();
  }

  increaseQuantity(item: any) {
    const productInCategory = this.categories
      .map(category => category.products) // Primero obtenemos los arrays de productos
      .reduce((acc, products) => acc.concat(products), []) // Aplanamos el array de arrays
      .find(product => product.id === item.id);

    if (productInCategory && item.quantity < productInCategory.stock) { // Validación de stock
      item.quantity += 1;
      this.calculateTotal();
    } else {
      this.notify.error2("No puede agregar más de lo que hay en stock.");
    }
  }

  decreaseQuantity(item: any) {
    if (item.quantity > 1) {
      item.quantity -= 1;
      this.calculateTotal();
    } else {
      this.removeFromBasket(item);
    }
  }

  removeFromBasket(item: any) {
    const index = this.basket.indexOf(item);
    if (index !== -1) {
      this.basket.splice(index, 1);
      this.calculateTotal();
    }
  }

  calculateTotal() {
    this.cartTotal = this.basket.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    this.applyDiscount();
  }

  applyDiscount() {
    if (this.discountPercentage > 100) {
      this.discountPercentage = 100;
    } else if (this.discountPercentage < 0) {
      this.discountPercentage = 0;
    }
    this.discountedTotal = this.cartTotal - (this.cartTotal * (this.discountPercentage / 100));
    this.taxAmount = this.discountedTotal * this.taxRate; // Calcula el IVA sobre el total con descuento
    this.calculateInstallments(this.selectedType);
  }


  openCheckoutModal(type: 'sale' | 'rental') {
    this.selectedType = type;
    this.displayModal = true;
    this.selectedClient = null;
    this.applyDiscount();
  }

  calculateInstallments(type: 'sale' | 'rental') {
    this.installments = [];

    if (this.discountedTotal <= 0 || this.installmentCount < 1) {
      return;
    }

    if (type === 'sale') {
      // Lógica para ventas: simple división del monto total por la cantidad de cuotas
      const installmentAmount = Math.floor(this.discountedTotal / this.installmentCount);
      let remainingAmount = this.discountedTotal;

      for (let i = 0; i < this.installmentCount; i++) {
        const amount = i === this.installmentCount - 1 ? remainingAmount : installmentAmount;
        remainingAmount -= amount;

        this.installments.push({
          number: i + 1,
          date: moment().add(i, 'months').format('YYYY-MM-DD'),
          amount
        });
      }
    } else if (type === 'rental') {
      // Lógica para arriendos: distribución entre fecha de inicio y fecha de fin
      if (!this.rentalStartDate || !this.rentalEndDate) {
        return;
      }

      const startDate = moment(this.rentalStartDate);
      const endDate = moment(this.rentalEndDate);
      const totalMonths = endDate.diff(startDate, 'months') + 1;
      const count = Math.min(this.installmentCount, totalMonths);
      const basicInstallmentAmount = Math.floor(this.discountedTotal / count);
      let remainingAmount = this.discountedTotal;

      for (let i = 0; i < count; i++) {
        let installmentDate = startDate.clone().add(i, 'months');

        // Ajuste de la última fecha de pago para coincidir con la fecha de devolución, si excede
        if (installmentDate.isAfter(endDate)) {
          installmentDate = endDate;
        }

        const amount = i === count - 1 ? remainingAmount : basicInstallmentAmount;
        remainingAmount -= amount;

        this.installments.push({
          number: i + 1,
          date: installmentDate.format('YYYY-MM-DD'),
          amount
        });
      }
    }
  }

  confirmCheckout() {
    if (!this.selectedClient) {
      Swal.fire("Seleccione un cliente", "", "warning");
      return;
    }

    if (this.selectedType === 'rental' && (!this.rentalStartDate || !this.rentalEndDate)) {
      Swal.fire("Complete la información del arriendo", "", "warning");
      return;
    }

    this.displayModal = false;
    this.selectedType === 'sale' ? this.saveSale() : this.saveRental();
  }

  // Modifica `saveSale()` para llamar a la guía de despacho de ventas
  saveSale() {
    const saleData = {
      orderId: `SALE-${moment().format('YYYYMMDDHHmmss')}`,
      sale_date: moment().format('YYYY-MM-DD'),
      carts: this.basket,
      amount: this.discountedTotal,
      discountPercentage: this.discountPercentage,
      installmentCount: this.installmentCount,
      installments: this.installments,
      payment_status: 'paid',
      status: 'completed',
      email: JSON.parse(localStorage.getItem('user'))?.email,
      created_at: moment().format(),
      updated_at: moment().format(),
      client: this.selectedClient
    };

    this.apiService.addSale(saleData).subscribe(
      (response) => {
        Swal.fire('Venta Generada', 'Su venta se ha realizado con éxito', 'success');
        this.generateDispatchGuide('sale', saleData);
      },
      (error) => {
        this.notify.error2('Error al generar la venta.');
        console.error('Error saving sale:', error);
      }
    );
  }

  saveRental() {
    const rentalData = {
      orderId: `RENT-${moment().format('YYYYMMDDHHmmss')}`,
      carts: this.basket,
      amount: this.discountedTotal,
      discountPercentage: this.discountPercentage,
      installmentCount: this.installmentCount,
      installments: this.installments,
      start_date: this.rentalStartDate,
      end_date: this.rentalEndDate,
      payment_status: 'pending',
      status: 'in progress',
      email: JSON.parse(localStorage.getItem('user'))?.email,
      client: this.selectedClient,
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.apiService.addRental(rentalData).subscribe(
      (response) => {
        Swal.fire('Arriendo Generado', 'Su arriendo se ha realizado con éxito', 'success');
        this.generateDispatchGuide('rental', rentalData);
      },
      (error) => {
        this.notify.error2('Error al generar el arriendo.');
        console.error('Error saving rental:', error);
      }
    );
  }

  // Método para generar la guía de despacho
  generateDispatchGuide(type: 'sale' | 'rental', data: any) {
    const guideService =
      type === 'sale'
        ? this.apiService.generateSaleDispatchGuide(data)
        : this.apiService.generateRentalDispatchGuide(data);

    guideService.subscribe(
      (response: Blob) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.download = `dispatch_guide_${type}_${moment().format('YYYYMMDDHHmmss')}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);
        this.clearCart();
        this.ngOnInit();
      },
      (error) => {
        this.notify.error2('Error al generar la guía de despacho.');
        console.error('Error generating dispatch guide:', error);
      }
    );
  }

  clearCart() {
    this.basket = [];
    this.discountPercentage = 0;
    this.installmentCount = 1;
    this.calculateTotal();
  }

  filterProducts() {
    if (this.searchTerm.trim()) {
      this.isGlobalFilterActive = true;

      const searchTermLower = this.searchTerm.toLowerCase();

      // Filtrar productos globalmente
      this.filteredGlobalProducts = this.allProducts.filter(product =>
        product.name.toLowerCase().includes(searchTermLower)
      );
    } else {
      // Restaurar el estado normal
      this.isGlobalFilterActive = false;
      this.filteredGlobalProducts = [];
      this.categories.forEach(category => {
        category.filteredProducts = [...category.products];
      });
    }
  }

}
