import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { Sale, Installment } from 'src/app/core/models';
import { NotifyService, ApiService } from 'src/app/core/services';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  sales: Sale[] = [];
  installments: Installment[] = [];  // Propiedad para almacenar las cuotas
  showInstallmentsModal: boolean = false;  // Controla la visibilidad del modal de cuotas



  constructor(private apiService: ApiService, public notify: NotifyService, private afs: AngularFirestore) { }

  ngOnInit() {
    this.fetch();
  }

  refresh() {
    this.fetch();
  }

  fetch() {
    this.apiService.getSales().subscribe(
      (res: Sale[]) => {
        this.sales = res;
        console.log(this.sales);
      },
      (error) => {
        console.error('Error fetching sales:', error);
      }
    );
  }

  viewInstallments(rentalId: string) {
    this.apiService.getSaleInstallments(rentalId).subscribe(
      (installments: Installment[]) => {
        this.installments = installments;
        this.showInstallmentsModal = true;
      },
      (error) => {
        this.notify.error2("Oops, an error occurred fetching installments.");
        console.error('Error fetching installments:', error);
      }
    );
  }

  closeInstallmentsModal() {
    this.showInstallmentsModal = false;
    this.installments = [];
  }

  sendNotificationEmail(installment: any) {
    this.apiService.sendInstallmentEmailNotification(installment.id).subscribe(
      () => {
        this.closeInstallmentsModal();
        this.notify.success("Notificación de correo enviada correctamente.");
        
      },
      (error) => {
        this.notify.error2("Error al enviar el correo de notificación.");
        console.error("Error sending email notification:", error);
      }
    );
  }
}
