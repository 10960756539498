import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Category } from 'src/app/core/models';
import { NotifyService, ApiService } from 'src/app/core/services';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: Category[] = [];
  display: boolean = false;
  category: any;

  constructor(private apiService: ApiService, public notify: NotifyService) {}

  ngOnInit() {
    this.fetch();
  }

  add() {
    this.category = {};
    this.display = true;
  }

  refresh() {
    this.fetch();
  }

  fetch() {
    this.apiService.getCategories().subscribe(
      (res: Category[]) => {
        this.categories = res;
        console.log(this.categories);
      },
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  delete(id: string) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminarlo'
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteCategory(id).subscribe(
          () => {
            Swal.fire('Deleted!', 'This category has been deleted.', 'success');
            this.fetch();
          },
          (error) => {
            this.notify.error2("Oops, an error occurred :(");
            console.error('Error deleting category:', error);
          }
        );
      }
    });
  }

  edit(category: Category) {
    this.category = category;
    this.display = true;
  }

  saver(formData: any) {
    if (!formData.isAddForm) {
      this.updateCategory(formData);
    } else {
      this.addCategory(formData);
    }
    this.display = false;
    this.category = {};
  }

  updateCategory(formData: any) {
    const data = {
      name: formData.name
    };

    this.apiService.updateCategory(formData.id, data).subscribe(
      () => {
        this.fetch();
      },
      (error) => {
        this.notify.error2("Oops, an error occurred :(");
        console.error('Error updating category:', error);
      }
    );
  }

  addCategory(formData: any) {
    const data = {
      name: formData.name
    };

    this.apiService.addCategory(data).subscribe(
      () => {
        this.fetch();
      },
      (error) => {
        this.notify.error2("Oops, an error occurred :(");
        console.error('Error adding category:', error);
      }
    );
  }
}
