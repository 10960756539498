import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  state: any;

  users: number;
  clients: number;
  rentals: number;
  sales: number;
  dispatchGuides: number;
  products: number;
  categories: number;

  constructor(private apiService: ApiService, private router: Router) {
    this.state = this.router.getCurrentNavigation()?.extras.state;
    if (this.state && this.state.reload) window.location.reload();
  }

  ngOnInit() {
    this.apiService.getUsers().subscribe((res: any[]) => {
      this.users = res.length;
    });

    this.apiService.getClients().subscribe((res: any[]) => {
      this.clients = res.length;
    });

    this.apiService.getRentals().subscribe((res: any[]) => {
      this.rentals = res.length;
    });

    this.apiService.getSales().subscribe((res: any[]) => {
      this.sales = res.length;
    });

    this.apiService.getDispatchGuides().subscribe((res: any[]) => {
      this.dispatchGuides = res.length;
    });

    this.apiService.getProducts().subscribe((res: any[]) => {
      this.products = res.length;
    });

    this.apiService.getCategories().subscribe((res: any[]) => {
      this.categories = res.length;
    });
  }
}
