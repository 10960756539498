export class Sale {
  id: string;
  saleDate: Date;
  totalPrice: number;
  paymentStatus: string;  // 'paid', 'installments', 'unpaid'
  installmentCount?: number;
  installmentAmount?: number;
  items: SaleItem[]; // Lista de productos en la venta
  createdAt: Date;
  updatedAt: Date;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.saleDate = obj.saleDate ? new Date(obj.saleDate) : null;
    this.totalPrice = obj.totalPrice;
    this.paymentStatus = obj.paymentStatus;
    this.installmentCount = obj.installmentCount;
    this.installmentAmount = obj.installmentAmount;
    this.items = obj.items ? obj.items.map((item: any) => new SaleItem(item)) : [];
    this.createdAt = obj.createdAt ? new Date(obj.createdAt) : null;
    this.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : null;
  }
}

export class SaleItem {
  productId: string;
  productName: string;
  quantity: number;
  unitPrice: number;

  constructor(obj: any = {}) {
    this.productId = obj.productId;
    this.productName = obj.productName;
    this.quantity = obj.quantity;
    this.unitPrice = obj.unitPrice;
  }
}
