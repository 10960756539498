<div class="col-md-12">
  <p-table dataKey="id" stateKey="categories" [value]="categories" styleClass="p-datatable-striped" [rows]="10"
    [paginator]="true" #yp1 lang="fr">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-md-6 text-start">
          <h5 class="mt-2">Categories</h5>
        </div>
        <div class="col-md-6 text-right">
          <button (click)="refresh()" pButton type="button" icon="pi pi-refresh"
            class="p-button-sm p-button-link mr-2"></button>
          <button (click)="add()" label="Add new category" pButton type="button" icon="pi pi-plus"
            class="p-button-sm"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Nombre
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon>
        </th>
        <th>Acciones </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-category>
      <tr>
        <td>{{ category.name }}</td>
        <td>
          <span class="badge bg-primary text-white">Habilitado</span>
        </td>
        <td class="text-right">
          <button (click)="edit(category)" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
          <button (click)="delete(category.id)" pButton type="button" icon="pi pi-trash"
            class="p-button-sm p-button-danger"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="d-none">
      <tr>
        <td colspan="7">No categories found.</td>
      </tr>
    </ng-template>
  </p-table>

</div>

<p-dialog *ngIf="display" [header]="category.name ? 'Category: ' + category.name : 'Add new'" [resizable]="true"
  [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" [style]="{width: '50vw'}">
  <app-category [display]="display" [category]="category" (call)="saver($event)"></app-category>
</p-dialog>