import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CategoriesComponent } from './categories/categories.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductsComponent } from './products/products.component';
import { CoreModule } from 'src/app/core/core.module';
import { SalesComponent } from './sales/sales.component';
import { RentalComponent } from './rental/rental.component';
import { ClientsComponent } from './clients/clients.component';
import { AuthGuardService } from 'src/app/core/services';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: ['admin', 'jefe_tienda','vendedor'] }
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: ['admin', 'jefe_tienda'] }
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: ['admin', 'jefe_tienda'] }
  },
  {
    path: 'sales',
    component: SalesComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: ['admin', 'jefe_tienda', 'vendedor'] }
  },
  {
    path: 'rental',
    component: RentalComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: ['admin', 'jefe_tienda', 'vendedor'] }
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: ['admin', 'jefe_tienda', 'vendedor'] }
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CoreModule,
    RouterModule.forChild(routes)
  ],
  exports: []
})
export class PrivateRoutingModule { }
