<div class="row">
  <div class="col-md-4">
    <div class="card cp" [routerLink]="['/admin/categories']">
      <div class="card-body">
        <h4 class="card-title">Categorias</h4>
        <p class="card-text">{{ categories }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card bg-primary cp" [routerLink]="['/admin/products']">
      <div class="card-body">
        <h4 class="card-title text-white">Productos</h4>
        <p class="card-text text-white">{{ products }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card cp" [routerLink]="['/admin/clients']">
      <div class="card-body">
        <h4 class="card-title">Clientes</h4>
        <p class="card-text">{{ clients }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card cp" [routerLink]="['/admin/rentals']">
      <div class="card-body">
        <h4 class="card-title">Arriendos</h4>
        <p class="card-text">{{ rentals }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card bg-primary cp" [routerLink]="['/admin/sales']">
      <div class="card-body">
        <h4 class="card-title text-white">Ventas</h4>
        <p class="card-text text-white">{{ sales }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card cp" [routerLink]="['/admin/users']">
      <div class="card-body">
        <h4 class="card-title">Usuarios</h4>
        <p class="card-text">{{ users }}</p>
      </div>
    </div>
  </div>

</div>