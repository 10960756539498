import { Rental } from './rental';
import { Sale } from './sales';

export class Client {
  id: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  rentals: Rental[];  // Lista de arriendos del cliente
  sales: Sale[];      // Lista de ventas del cliente

  constructor(obj: any = {}) {
      this.id = obj.id;
      this.name = obj.name;
      this.email = obj.email;
      this.phone = obj.phone;
      this.address = obj.address;
      
      this.rentals = obj.rentals ? obj.rentals.map((rental: any) => new Rental(rental)) : [];
      this.sales = obj.sales ? obj.sales.map((sale: any) => new Sale(sale)) : [];
  }
}