<div class="col-md-12">
  <p-table dataKey="id" stateKey="sales" [value]="sales" styleClass="p-datatable-striped" [rows]="10" [paginator]="true"
    #yp1 lang="fr">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-md-6 text-start">
          <h5 class="mt-2">Ventas</h5>
        </div>
        <div class="col-md-6 text-right">
          <button (click)="refresh()" pButton type="button" icon="pi pi-refresh"
            class="p-button-sm p-button-link"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id">Order ID <p-sortIcon field="id"></p-sortIcon></th>
        <th class="text-right" pSortableColumn="client.name">Cliente <p-sortIcon field="client.name"></p-sortIcon></th>
        <th class="text-right" pSortableColumn="total_price">Total Price <p-sortIcon field="total_price"></p-sortIcon>
        </th>
        <th pSortableColumn="items">Products
          <p-columnFilter type="text" field="items" matchMode="contains" display="menu"></p-columnFilter>
        </th>
        <th pSortableColumn="payment_status">Status <p-sortIcon field="payment_status"></p-sortIcon></th>
        <th class="text-right" pSortableColumn="sale_date">Sale Date <p-sortIcon field="sale_date"></p-sortIcon></th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sale>
      <tr>
        <td>{{ sale.id }}</td>
        <td class="text-right">{{ sale.client.name }}</td>
        <td class="text-right">{{ sale.total_price | currency:'USD':'symbol':'1.0-0' }}</td>
        <td>
          <span *ngFor="let item of sale.items" class="badge badge-light mr-1 font-weight-normal">
            {{ item.quantity }} x {{ item.product_name }}
          </span>
        </td>
        <td>
          <span class="badge bg-primary text-white" *ngIf="sale.payment_status === 'paid'">Paid</span>
          <span class="badge bg-warning text-dark" *ngIf="sale.payment_status === 'installments'">Installments</span>
          <span class="badge bg-danger text-white" *ngIf="sale.payment_status === 'unpaid'">Unpaid</span>
        </td>
        <td class="text-right">{{ sale.sale_date | date: "dd/MM/yyyy" }}</td>
        <td>
          <button (click)="viewInstallments(sale.id)" pButton type="button" icon="pi pi-eye"
            class="p-button-sm p-button-info"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">No sales found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- Modal para mostrar las cuotas -->
<p-dialog header="Cuotas" [(visible)]="showInstallmentsModal" [modal]="true" [style]="{width: '50vw'}">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th># Cuota</th>
        <th>Fecha</th>
        <th>Monto</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let installment of installments">
        <td>{{ installment.installment_number }}</td>
        <td>{{ installment.date | date: 'dd/MM/yyyy' }}</td>
        <td>{{ installment.amount | currency: 'USD':'symbol':'1.0-0' }}</td>
        <td>
          <button (click)="sendNotificationEmail(installment)" pButton label="Enviar Correo" icon="pi pi-envelope"
            class="p-button-info p-button-sm"></button>
        </td>
      </tr>
    </tbody>
  </table>
  <p-footer>
    <button (click)="closeInstallmentsModal()" pButton label="Cerrar" icon="pi pi-times"
      class="p-button-secondary"></button>
  </p-footer>
</p-dialog>